import {
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useQueryClient } from 'react-query';
import {
  insuranceRequestKeys,
  useCreateTicket,
} from '@axo/shared/data-access/hooks';
import { DataAccessContext } from '@axo/shared/data-access/provider';
import { event, insurance_request } from '@axo/shared/data-access/types';

enum Status {
  None,
  Connecting,
  Connected,
  Closing,
  Disconnected,
  Error,
}

export function useWebSocket(ws_url?: string) {
  const { mutateAsync: createTicket } = useCreateTicket();
  const [latestMessage, setLatestMessage] = useState<MessageEvent | null>(null);

  const [retryCount, setRetryCount] = useState(0);
  const [status, setStatus] = useState(Status.None);
  const retry = useCallback(() => {
    setRetryCount((prev) => prev + 1);
  }, []);
  const manualRetry = useMemo(() => {
    const isFailed = status === Status.Error || status === Status.Disconnected;
    if (retryCount >= MAX_RETRIES && isFailed) {
      return () => setRetryCount(0);
    }
    return null;
  }, [retryCount, status]);
  const refreshTicket = useMemo(() => {
    if (retryCount >= MAX_RETRIES) return null;
    return () => createTicket();
  }, [createTicket, retryCount]);

  useEffect(() => {
    let next: WebSocket | null = null,
      canceled = false;

    function onMessage(e: MessageEvent) {
      setLatestMessage(e);
    }

    function onClose() {
      setStatus(Status.Disconnected);
      retry();
    }

    function onError() {
      setStatus(Status.Error);
      retry();
    }

    async function connect() {
      if (!ws_url) return;
      if (!refreshTicket) return;
      const ticket = await refreshTicket();
      if (canceled) return;
      const url = new URL(ws_url);
      url.searchParams.set('ticket', ticket.ID);
      next = new WebSocket(url);
      setStatus(Status.Connecting);
      next.addEventListener('message', onMessage);
      next.addEventListener('close', onClose);
      next.addEventListener('error', onError);
    }

    connect();

    return () => {
      canceled = true;
      if (next) {
        if (!next.CLOSED) {
          next.removeEventListener('message', onMessage);
          next.removeEventListener('close', onClose);
          next.removeEventListener('error', onError);
          next.close();
          setStatus(Status.Closing);
        }
      }
    };
  }, [refreshTicket, retry, ws_url]);

  return useMemo(
    () => ({ latestMessage, manualRetry, status }),
    [latestMessage, manualRetry, status]
  );
}

const MAX_RETRIES = 3;

export function WebSocketProvider({ children }: { children: ReactNode }) {
  const { state, url } = useContext(DataAccessContext);
  const { latestMessage, manualRetry } = useWebSocket(url?.ws);
  const client = useQueryClient();

  useEffect(() => {
    if (latestMessage) {
      const event = JSON.parse(latestMessage.data) as event.Event<unknown>;

      switch (event.source) {
        case 'insurance-request':
          switch (event.code) {
            case 'insurance-request-updated':
              {
                const data =
                  event.data as event.EventData<insurance_request.Request>;
                if (data.new) {
                  const requestID = data.new.ID;
                  client.invalidateQueries(
                    insuranceRequestKeys.root({
                      insuranceRequestID: requestID,
                    })
                  );
                }
              }
              break;
          }
      }
    }
  }, [latestMessage, client, state.user.token, url.api]);

  useEffect(() => {
    if (manualRetry) {
      // AppToaster.show({
      //   intent: 'danger',
      //   message: 'WebSocket disconnected. Retry?',
      //   action: {
      //     text: 'Retry connection',
      //     onClick: manualRetry,
      //   },
      // });
    }
  }, [manualRetry]);

  return <>{children}</>;
}
