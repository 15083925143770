/* eslint-disable react/jsx-pascal-case */
import { Icons } from '@axo/deprecated/util/ui-components';
import {
  InsuranceForm,
  InsuranceProvider,
  OnEnd,
  OnError,
  PoweredByAig,
  getInitialAppContextState,
  getInitialDataAccessContextState,
  getInitialInsuranceContextState,
  useTranslation,
} from '@axo/insurance/feature/dk';
import {
  AuthProvider,
  WebSocketProvider,
} from '@axo/insurance/feature/providers';
import { DataAccessProvider } from '@axo/shared/data-access/provider';
import { LoanApplicationProvider } from '@axo/shared/feature/providers';
import { Trustpilot } from '@axo/shared/ui/core';
import { QueryClient } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { Error as ErrorPage } from '../../../components/Error';
import { insuranceSourceTag } from '../../../consts/sourceTag';
import LendMeLogo from './LendMeLogo.svg';
import styles from './Main.module.scss';

const queryClient = new QueryClient();

export function Main({ onEnd, onError }: { onEnd: OnEnd; onError: OnError }) {
  const search = new URLSearchParams(window.location.search);
  const { t } = useTranslation();

  return (
    <DataAccessProvider
      queryClient={queryClient}
      url={{
        api: import.meta.env.VITE_APP_API_URL,
        // ws: import.meta.env.VITE_APP_WS_URL,
      }}
      storedInitialState={getInitialDataAccessContextState(search)}
    >
      <LoanApplicationProvider initialState={getInitialAppContextState(search)}>
        <ErrorPage>
          <AuthProvider onError={onError}>
            <WebSocketProvider>
              <InsuranceProvider
                onEnd={onEnd}
                sourceTag={insuranceSourceTag}
                initialState={getInitialInsuranceContextState(search)}
              >
                <div className={styles.container}>
                  <header>
                    <Icons.Menu size="md" color={{ primary: '--dark-900' }} />
                    <img
                      src={LendMeLogo}
                      alt={t('Lendme income protection insurance')}
                    />
                    <Icons.User size="md" color={{ primary: '--dark-900' }} />
                  </header>
                  <main className={styles.contentSplit}>
                    <div className={styles.trustpilot}>
                      <PoweredByAig layout="desktop" />
                      {/* TODO: add trustpilot data */}
                      <Trustpilot
                        businessId="577008a20000ff0005919d8a"
                        templateId="53aa8807dec7e10d38f59f32"
                        height="120px"
                        locale="da-DK"
                        link="https://www.trustpilot.com/review/lendme.dk"
                      />
                    </div>

                    <div className={styles.mainColumn}>
                      <InsuranceForm
                        onEnd={onEnd}
                        onError={onError}
                      ></InsuranceForm>
                    </div>
                  </main>
                </div>
                <ReactQueryDevtools initialIsOpen={false} />
              </InsuranceProvider>
            </WebSocketProvider>
          </AuthProvider>
        </ErrorPage>
      </LoanApplicationProvider>
    </DataAccessProvider>
  );
}
