import { ContentBox, MessageBox } from '@axo/deprecated/util/ui-components';
import { InfoType, RadioGroup } from '@axo/shared/ui/input';
import { ForwardedRef, forwardRef, useState } from 'react';
import { ChildFormProps } from '../InsuranceForm';
import styles from '../InsuranceForm/InsuranceForm.module.scss';
import { useTranslation } from '../translations/useTranslation';

function Questionnaire(
  { applyValidation, reportValidity }: ChildFormProps,
  ref: ForwardedRef<HTMLInputElement>
) {
  const { t } = useTranslation();
  const [existingInsurance, setExistingInsurance] = useState('');
  const [expectedPayouts, setExpectedPayouts] = useState('');

  return (
    <ContentBox
      classes={{
        root: styles.contentBox,
      }}
      ref={ref}
    >
      <RadioGroup
        name="existingInsurance"
        label={t(
          'Do you currently have salary insurance that covers your salary if you become unemployed or on sick leave?'
        )}
        radioStyle="both"
        buttonHeight="regular"
        infoBox={
          !existingInsurance && applyValidation
            ? {
                message: t(
                  'Please make a selection in order to proceed with the application.'
                ),
                type: InfoType.ERROR,
              }
            : undefined
        }
        onChange={(e) => {
          setExistingInsurance(e.target.value);
          reportValidity(!!e.target.value && !!expectedPayouts);
        }}
        options={[
          { text: t('Yes'), value: 'Yes' },
          { text: t('No'), value: 'No' },
        ]}
      />
      {existingInsurance === 'Yes' && (
        <MessageBox variety="info">
          {t(
            'Then you should be aware that this insurance does not give you extra money if you become unemployed or sick, unless you cancel your current salary insurance. If you are not covered for the maximum amount, you can purchase additional coverage up to this amount.'
          )}
        </MessageBox>
      )}
      {existingInsurance === 'No' && (
        <MessageBox variety="info">
          {t(
            'Then you should consider buying this insurance which can insure you in the event of involuntary unemployment, illness, certain critical illnesses and death.'
          )}
        </MessageBox>
      )}
      <RadioGroup
        name="expectedPayouts"
        label={t(
          'Do you expect to receive cash benefits if you are made redundant?'
        )}
        radioStyle="both"
        buttonHeight="regular"
        infoBox={
          !expectedPayouts && applyValidation
            ? {
                message: t(
                  'Please make a selection in order to proceed with the application.'
                ),
                type: InfoType.ERROR,
              }
            : undefined
        }
        onChange={(e) => {
          setExpectedPayouts(e.target.value);
          reportValidity(!!e.target.value && !!existingInsurance);
        }}
        options={[
          { text: t('Yes'), value: 'Yes' },
          { text: t('No'), value: 'No' },
        ]}
      />
      {expectedPayouts === 'Yes' && (
        <MessageBox variety="info">
          {t(
            'Payments from involuntary unemployment and illness will then be 100% set off in your cash benefit.'
          )}
        </MessageBox>
      )}
      {expectedPayouts === 'No' && (
        <MessageBox variety="info">
          {t(
            'Then you will be able to buy this insurance with advantage, and get an extra payout in the event of unemployment and illness up to 60% of your current income after deduction for labor market contributions.'
          )}
        </MessageBox>
      )}
    </ContentBox>
  );
}
export default forwardRef(Questionnaire);
