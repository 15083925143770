import { insurance_payment } from '@axo/shared/data-access/types';
import { IAPI } from '../../lib/models/api';

export const resourceUri = '/insurance-payment-mandate';

export const createMandate = <
  TRequestParams = insurance_payment.CreateCardPaymentMandateRequest,
  TResponse = insurance_payment.Payment
>(
  mandateType: insurance_payment.PaymentMandateType
) => {
  return function createMandate({ url: { service: baseURL }, token }: IAPI) {
    return async function create(request: TRequestParams): Promise<TResponse> {
      const url = new URL(`${resourceUri}/${mandateType}`, baseURL);
      const response = await fetch(url, {
        method: 'POST',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          Authorization: token,
        },
        body: JSON.stringify(request),
      });
      if (!response.ok) {
        throw new Error(`Network response was not OK: ${response.status}`);
      }
      return (await response.json()) as TResponse;
    };
  };
};

export const findMandates = <TResponse = insurance_payment.FindPaymentResponse>(
  mandateType: insurance_payment.PaymentMandateType
) => {
  return function findMandates({ url: { service: baseURL }, token }: IAPI) {
    return async function find(
      request: insurance_payment.FindPaymentInput
    ): Promise<TResponse> {
      const url = new URL(`${resourceUri}/${mandateType}`, baseURL);
      for (const [key, value] of Object.entries(request)) {
        url.searchParams.set(key, value);
      }
      const response = await fetch(url, {
        method: 'GET',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          Authorization: token,
        },
      });
      if (!response.ok) {
        throw new Error(`Network response was not OK: ${response.status}`);
      }
      return (await response.json()) as TResponse;
    };
  };
};

export const getMandate = <TResponse = insurance_payment.Payment>(
  mandateType: insurance_payment.PaymentMandateType
) => {
  return function getMandate({ url: { service: baseURL }, token }: IAPI) {
    return async function get(id: string): Promise<TResponse> {
      const url = new URL(`${resourceUri}/${mandateType}/${id}`, baseURL);
      const response = await fetch(url, {
        method: 'GET',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          Authorization: token,
        },
      });
      if (!response.ok) {
        throw new Error(`Network response was not OK: ${response.status}`);
      }
      return (await response.json()) as TResponse;
    };
  };
};
