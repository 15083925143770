type RedirectArgs = {
  uid?: string;
  token?: string;
};

export const redirect = async (args?: RedirectArgs) => {
  let redirectUrl = new URL(import.meta.env?.VITE_APP_LEGACY_LOGIN);

  if (!args) {
    // Redirect user
    window.location.assign(redirectUrl.href);
    return;
  }

  const { token, uid } = args;

  const params = new URLSearchParams(window.location.search);

  if (params.has('redirectUrl')) {
    redirectUrl = new URL(
      params.get('redirectUrl') ?? import.meta.env?.VITE_APP_LEGACY_LOGIN
    );
    if (uid) redirectUrl.searchParams.set('id', uid);
    if (token) redirectUrl.hash = `token=${token}`;

    // Redirect user
    window.location.assign(redirectUrl.href);
    return;
  }

  // ? My Page DK does not show new offers that comes in from the banks.
  // ? So we need to redirect user to Login page instead, to give time for the banks to process the application.
  // ? If My Page DK gets faster or shows new offers, we can remove this.
  // if (token && uid) {
  //   // Application from 1P Form DK
  //   // Temporary workaround while My Page DK is on legacy
  //   if (params.get('source') === '1P') {
  //     redirectUrl = await getLegacyRedirect({
  //       redirectURL: import.meta.env.VITE_APP_REDIRECT_URL,
  //       id: uid,
  //       jwt: token,
  //     });
  //     // Redirect user to legacy DK
  //     window.location.assign(redirectUrl.href);
  //     return;
  //   }

  //   redirectUrl = new URL(
  //     `${import.meta.env.VITE_APP_REDIRECT_URL}/${uid}/${token}`
  //   );
  // }

  // Redirect user
  window.location.assign(redirectUrl.href);
};
