import { A } from '@axo/deprecated/util/ui-components';
import { Params } from '@axo/shared/util/translation';

export const translations = {
  // general
  'Ok, I understand': () => ({
    en: 'Ok, I understand',
    dk: 'OK, jeg forstår',
  }),

  Yes: () => ({
    en: 'Yes',
    dk: 'Ja',
  }),

  No: () => ({
    en: 'No',
    dk: 'Nej',
  }),

  Edit: () => ({
    en: 'Edit',
    dk: 'Rediger',
  }),

  Save: () => ({
    en: 'Save',
    dk: 'Gem',
  }),

  month: () => ({
    en: 'month',
    dk: 'måned',
  }),

  'Monthly payment': () => ({
    en: 'Monthly payment',
    dk: 'Månedlig pris',
  }),

  'Value insured': () => ({
    en: 'Value insured',
    dk: 'Forsikringssum',
  }),

  // header
  'Lendme income protection insurance': () => ({
    en: 'Lendme income protection insurance',
    dk: 'LendMe Økonomiforsikring',
  }),

  "The insurance is taken out with AIG Insurance, which is one of the world's leading insurance companies.":
    () => ({
      en: "The insurance is taken out with AIG Insurance, which is one of the world's leading insurance companies.",
      dk: 'Forsikringen tegnes hos AIG Forsikring, der er et af verdens største forsikringsselskaber.',
    }),

  'Powered by': () => ({
    en: 'Powered by',
    dk: 'leveret i samarbejde med',
  }),

  'Application status': () => ({
    en: 'Application status',
    dk: 'Ansøgningsstatus',
  }),

  'Your application has been submitted': () => ({
    en: 'Your application has been submitted',
    dk: 'Vi har modtaget din låneansøgning',
  }),

  'You are eligible for income insurance': () => ({
    en: 'You are eligible for income insurance',
    dk: 'Du kan tegne en økonomiforsikring',
  }),

  'Check your loan application offers': () => ({
    en: 'Check your loan application offers',
  }),

  'If you already have or do not want to add income insurance, you can directly go to “My Page” to check your offers.':
    () => ({
      en: 'If you already have or do not want to add income insurance, you can directly go to “My Page” to check your offers.',
      dk: 'Hvis du allerede har, eller ikke ønsker Økonomiforsikring, kan du gå direkte til Min side for at se dine lånetilbud.',
    }),

  // explainer
  '<User name> would you like to get financial security?': ([
    name,
  ]: Params) => ({
    en: name
      ? `${name} would you like to get financial security?`
      : 'Would you like to get financial security?',
    dk: name
      ? `${name}, mangler du et økonomisk sikkerhedsnet?`
      : 'Ønsker du økonomisk tryghed ',
  }),

  'Since anyone can have an accident, Lendme strongly recommends that you protect your finances with Lendme Økonomiforsikring.':
    () => ({
      en: 'Since anyone can have an accident, Lendme strongly recommends that you protect your finances with Lendme Økonomiforsikring.  The insurance helps you maintain financial stability by covering your monthly loan payments in unexpected life situations:',
      dk: 'Med en LendMe Økonomiforsikring er du sikret en række fordele, hvis du pludselig bliver ledig eller syg.',
    }),

  'Maintain financial peace of mind even in emergency situations': () => ({
    en: 'Maintain financial peace of mind even in emergency situations',
    dk: 'Få hverdagen til at hænge sammen, hvis du bliver syg eller ledig',
  }),

  'Keep your finances stable when unemployment hits or you get sick': () => ({
    en: 'Keep your finances stable when unemployment hits or you get sick',
    dk: 'Kontant udbetaling til dine efterladte i tilfælde af død',
  }),

  'Payment protection is valid around the clock and around the world for the first 12 months':
    () => ({
      en: 'Payment protection is valid around the clock and around the world for the first 12 months',
      dk: 'Ingen krav om medlemskab af a-kasse eller deling af sundhedsoplysninger',
    }),

  'Read more about insurance here.': () => ({
    en: (
      <>
        Read more about insurance{' '}
        <a
          href="https://lendme.dk/oekonomiforsikring"
          target="_blank"
          rel="noreferrer"
        >
          here
        </a>
        .
      </>
    ),
    dk: (
      <>
        Læs mere om forsikringen{' '}
        <a
          href="https://lendme.dk/oekonomiforsikring"
          target="_blank"
          rel="noreferrer"
        >
          her
        </a>
        .
      </>
    ),
  }),

  'Select your desired coverage': () => ({
    en: 'Select your desired coverage',
    dk: 'Vælg din månedlige dækning',
  }),

  'Assessment of insurance requirements and needs': () => ({
    en: 'Assessment of insurance requirements and needs',
    dk: 'Vurdering af krav og behov for forsikringen',
  }),

  'The main element of the insurance is a monthly compensation in the event of involuntary unemployment.':
    () => ({
      en: 'The main element of the insurance is a monthly compensation in the event of involuntary unemployment.',
      dk: 'Det primære element i forsikringen er en månedlig udbetaling til dig i tilfælde af ufrivillig arbejdsløshed.',
    }),

  'The insurance is therefore primarily designed to meet the requirements and needs of consumers who may have difficulties covering their expenses, or who need greater financial support.':
    () => ({
      en: 'The insurance is therefore primarily designed to meet the requirements and needs of consumers who may have difficulties covering their expenses, or who need greater financial support.',
      dk: 'Forsikringen er derfor designet til at hjælpe dig i tilfælde, hvor du vil have svært ved at dække dine udgifter, og dig som ønsker øget økonomisk tryghed.',
    }),

  'We recommend that you go through the following four steps to assess whether the cover will be suitable for you:':
    () => ({
      en: 'We recommend that you go through the following four steps to assess whether the cover will be suitable for you:',
      dk: 'Vi anbefaler at du gennemgår følgende fire trin for at vurdere, hvorvidt Økonomiforsikring er relevant for dig:',
    }),

  'It is a condition for the insurance to come into force that you:': () => ({
    en: 'It is a condition for the insurance to come into force that you:',
    dk: 'Det er en betingelse ved forsikringens ikrafttræden at du:',
  }),

  'Create a budget to get an overview of what monthly expenses you want to incur, should you become involuntarily unemployed.':
    () => ({
      en: 'Create a budget to get an overview of what monthly expenses you want to incur, should you become involuntarily unemployed.',
      dk: 'Udarbejd et budget, der giver overblik over hvilke månedlige udgifter du fortsat skal betale ved arbejdsløshed.',
    }),

  'Consider what other intel: you would be able to have in this situation (e.g. unemployment benefits from NAV and/or other insurances, sickness benefits, saved funds, etc.), and whether you would be comfortable with the level of your total income in such a situation.':
    () => ({
      en: 'Consider what other intel: you would be able to have in this situation (e.g. unemployment benefits from NAV and/or other insurances, sickness benefits, saved funds, etc.), and whether you would be comfortable with the level of your total income in such a situation.',
      dk: 'Overvej hvilke andre indtægtskilder du har (fx dagpenge eller kontanthjælp), og om du vil være tryg ved niveauet af din samlede indkomst i sådan en situation.',
    }),

  "If the answer to the savings question in point 2 is 'no', please consider whether you wish to keep insurance that gives you the opportunity to increase your total monthly income up to 12 months in case of involuntary unemployment":
    () => ({
      en: "If the answer to the savings question in point 2 is 'no', please consider whether you wish to keep insurance that gives you the opportunity to increase your total monthly income up to 12 months in case of involuntary unemployment",
      dk: "Hvis svaret på indtægtsspørgsmålet i punkt 2 er 'nej', bedes du overveje at tilvælge en forsikring, der giver dig mulighed for at øge din samlede månedlige indtægt op til 12 måneder i tilfælde af ufrivillig arbejdsløshed",
    }),

  'If you have come to the conclusion that insurance will be beneficial, the budget will be able to help you choose an appropriate insurance amount per month. remember that the compensation paid from the insurance in the event of involuntary unemployment a maximum of 60 per cent of your monthly average net income can be issued in them the three months immediately before the oposigeisen.':
    () => ({
      en: 'If you have come to the conclusion that insurance will be beneficial, the budget will be able to help you choose an appropriate insurance amount per month. remember that the compensation paid from the insurance in the event of involuntary unemployment a maximum of 60 per cent of your monthly average net income can be issued in them the three months immediately before the oposigeisen.',
      dk: 'Hvis du er kommet frem til, at forsikring vil være gavnlig, vil dit budget kunne hjælpe dig med at vælge et passende forsikringsbeløb om måneden. Husk, at den erstatning, der udbetales fra forsikringen i tilfælde af ufrivillig arbejdsløshed, maksimalt kan være 60 procent af din månedlige gennemsnitlige nettoindkomst.',
    }),

  'The insurance also covers death as a result of illness or accident and replaces the estate with a lump sum of Nox 50 oco to be used for e.g. funeral expenses.':
    () => ({
      en: 'The insurance also covers death as a result of illness or accident and replaces the estate with a lump sum of Nox 50 oco to be used for e.g. funeral expenses.',
      dk: 'Forsikringen inkluderer også en engangsudbetaling på 50.000 kr i tilfælde ad død.',
    }),

  'Insurance benefits': () => ({
    en: 'Insurance benefits',
    dk: 'Forsikringsfordele',
  }),

  'You decide for yourself what you use the compensation for, for example house rent, mortgage costs, heating or food.':
    () => ({
      en: 'You decide for yourself what you use the compensation for, for example house rent, mortgage costs, heating or food.',
      dk: 'Du bestemmer selv hvad du bruger udbetalingen til, fx husleje, banklån, EL, varme eller mad og drikke.',
    }),

  'Your insurance follows you, not your county. This means that your insurance remains valid though':
    () => ({
      en: 'Your insurance follows you, not your county. This means that your insurance remains valid though',
      dk: 'Forsikringen følger dig, og ikke dine lån. Forsikringen forbliver aktiv selvom du måtte indfri dit lån.',
    }),

  'Get a copy of the insurance details below:': () => ({
    en: 'Get a copy of the insurance details below:',
    dk: 'Læs mere om forsikringen her:',
  }),

  'Product fact-sheet': () => ({
    en: 'Product fact-sheet',
    dk: 'Produktark',
  }),

  'Pre-purchase information': () => ({
    en: 'Pre-purchase information',
    dk: 'Førkøbsinformation',
  }),

  'Continue with insurance': () => ({
    en: 'Continue with insurance',
    dk: 'Tilkøb forsikring',
  }),

  'Skip and see offers': () => ({
    en: 'Skip and see offers',
    dk: 'Fortsæt uden forsikring',
  }),

  // confirmation
  'Confirm purchase': () => ({
    en: 'Confirm purchase',
    dk: 'Bekræft tilkøb',
  }),

  'Do you currently have salary insurance that covers your salary if you become unemployed or on sick leave?':
    () => ({
      en: 'Do you currently have salary insurance that covers your salary if you become unemployed or on sick leave?',
      dk: 'Har du allerede en forsikring, der dækker din løn, hvis du bliver ledig eller sygemeldt?',
    }),

  'Then you should be aware that this insurance does not give you extra money if you become unemployed or sick, unless you cancel your current salary insurance. If you are not covered for the maximum amount, you can purchase additional coverage up to this amount.':
    () => ({
      en: 'Then you should be aware that this insurance does not give you extra money if you become unemployed or sick, unless you cancel your current salary insurance. If you are not covered for the maximum amount, you can purchase additional coverage up to this amount.',
      dk: 'Vær opmærksom på at denne forsikring ikke giver dig ekstra penge hvis du bliver arbejdsløs eller sygemeldt, medmindre du opsiger din nuværende lønsikring. Hvis du ikke er dækket for det maksimale beløb, kan du købe ekstra dækning op til det beløb.',
    }),

  'Then you should consider buying this insurance which can insure you in the event of involuntary unemployment, illness, certain critical illnesses and death.':
    () => ({
      en: 'Then you should consider buying this insurance which can insure you in the event of involuntary unemployment, illness, certain critical illnesses and death.',
      dk: 'Du bør overveje at tilkøbe denne forsikring som kan sikre dig udbetaling i tilfælde af arbejdsløshed, sygdom eller ved dødsfald.',
    }),

  'Do you expect to receive cash benefits if you are made redundant?': () => ({
    en: 'Do you expect to receive cash benefits if you are made redundant?',
    dk: 'Forventer du at modtage kontanthjælp, hvis du bliver afskediget?',
  }),

  'Payments from involuntary unemployment and illness will then be 100% set off in your cash benefit.':
    () => ({
      en: 'Payments from involuntary unemployment and illness will then be 100% set off in your cash benefit',
      dk: 'Udbetalinger ved arbejdsløshed og sygdom modregnes herefter 100 % i din kontanthjælp.',
    }),

  'Then you will be able to buy this insurance with advantage, and get an extra payout in the event of unemployment and illness up to 60% of your current income after deduction for labor market contributions.':
    () => ({
      en: 'Then you will be able to buy this insurance with advantage, and get an extra payout in the event of unemployment and illness up to 60% of your current income after deduction for labor market contributions.',
      dk: 'Du vil med fordel kunne købe denne forsikring, og få en ekstra udbetaling ved arbejdsløshed og sygdom, og få op til 60 % af din nuværende indkomst efter fradrag for arbejdsmarkedsbidrag.',
    }),

  'Please make a selection in order to proceed with the application.': () => ({
    en: 'Please make a selection in order to proceed with the application.',
    dk: 'Venligst foretag et valg for at kunne fortsætte',
  }),

  'Eligibility criteria': () => ({
    en: 'Eligibility criteria',
    dk: 'Betingelser',
  }),

  'I am 18-65 years old': () => ({
    en: 'I am 18-65 years old',
    dk: 'Er fyldt 18 år, men ikke 65 år',
  }),

  'I live permanently in Denmark and I have a valid mitID as proof of belonging to Danish social security based on residence':
    () => ({
      en: 'I live permanently in Denmark and I have a valid mitID as proof of belonging to Danish social security based on residence',
      dk: 'Er beskæftiget som lønmodtager i Danmark (eksl Grønland og Færøerne) og aflønnet mindst 16 timer pr. uge i mindst 12 måneder',
    }),

  'I am permanently employed and work at least 16 hours a week': () => ({
    en: 'I am permanently employed and work at least 16 hours a week',
    dk: 'Ikke er ansat i fleksjob, i et vikariat eller lignende ordning med løntilskud',
  }),

  'I am fully able to work and am not aware of future layoffs or layoffs':
    () => ({
      en: 'I am fully able to work and am not aware of future layoffs or layoffs',
      dk: 'Ikke er bekendt med, eller burde have kendskab til, kommende arbejdsløshed eller din arbejdsgivers planer om for eksempel at nedlægge din stilling',
    }),

  'I am not aware of any serious illness or chronic condition that could lead to sick leave. In addition, I am not aware of possible future sickness absences and I understand that the insurance only applies to accidents or illnesses that occur during the insurance period':
    () => ({
      en: 'I am not aware of any serious illness or chronic condition that could lead to sick leave. In addition, I am not aware of possible future sickness absences and I understand that the insurance only applies to accidents or illnesses that occur during the insurance period',
      dk: 'Er indforstået med, at sygdom eller personskade som du har, eller burde have kendskab til, ved forsikringens etablering og som du senere anmelder, ikke er dækket af forsikringen',
    }),

  'I confirm that I meet the above conditions for purchasing the insurance. I also confirm that I have read the document with advance information, the insurance conditions and the document with information about the insurance.':
    ([
      advanceInformationLink,
      insuranceConditionsLink,
      insuranceInformationLink,
    ]: Params) => ({
      en: 'I confirm that I meet the above conditions for purchasing the insurance. I also confirm that I have read the document with advance information, the insurance conditions and the document with information about the insurance.',
      dk: (
        <>
          Jeg bekræfter at jeg opfylder ovenstående betingelser for køb af
          forsikringen. Jeg bekræfter også at have læst dokument med{' '}
          <A
            href={advanceInformationLink.toString()}
            target="_blank"
            rel="noreferrer"
          >
            forhåndsinformation
          </A>
          ,{' '}
          <A
            href={insuranceConditionsLink.toString()}
            target="_blank"
            rel="noreferrer"
          >
            forsikringsbetingelserne
          </A>{' '}
          og{' '}
          <A
            href={insuranceInformationLink.toString()}
            target="_blank"
            rel="noreferrer"
          >
            dokument med oplysninger om forsikringen
          </A>
          .
        </>
      ),
    }),

  'I understand that the insurance is valid regardless of whether I am granted a loan or not.':
    () => ({
      en: 'I understand that the insurance is valid regardless of whether I am granted a loan or not.',
      dk: 'Jeg er indforstået med, at forsikringen tegnes uanset om et lån optages eller ej. Bemærk, at der altid gælder 14 dages fortrydelsesret fra policen modtages.',
    }),

  'Account details': () => ({
    en: 'Account details',
    dk: 'Konto',
  }),

  'Account number': () => ({
    en: 'Account number',
    dk: 'Kontonummer',
  }),

  'Registration number': () => ({
    en: 'Registration number',
    dk: 'Reg. nr',
  }),

  'I agree that payment is made to AIG via payment service from the following account: <XXXX - XXXXXXX>.':
    ([account]: Params) => ({
      en: `I agree that payment is made to AIG via payment service from the following account: ${account}.`,
      dk: `Jeg accepterer, at betaling foretages til LendMe over betalingsservice fra følgende konto: ${account}`,
    }),

  'Complete purchase': () => ({
    en: 'Complete purchase',
    dk: 'Tilkøb forsikring',
  }),

  // summary
  'Thank you for your purchase': () => ({
    en: 'Thank you for your purchase',
    dk: 'Tak for dit køb',
  }),

  'We have registered your purchase of LendMe Financial Protection. You will receive an email with more details about your new insurance within 24 hours.':
    () => ({
      en: 'We have registered your purchase of LendMe Financial Protection. You will receive an email with more details about your new insurance within 24 hours.',
      dk: 'Vi har registreret dit køb af en LendMe Økonomisikring. Du modtager en mail med flere detaljer om din nye forsikring indenfor 24 timer.',
    }),

  'An email with details about the insurance will be sent to you within 24 hours. If you have any further questions, do not hesitate to contact us.':
    () => ({
      en: 'An email with details about the insurance will be sent to you within 24 hours. If you have any further questions, do not hesitate to contact us.',
      dk: 'En e-mail med detaljer om din Økonomiforsikring® vil blive sendt til dig indenfor 24 timer. Hvis du har spørgsmål, så tøv ikke med at kontakte os.',
    }),

  'Please complete the card details where your insurance monthly pay will be deducted from.':
    () => ({
      en: 'Please complete the card details where your insurance monthly pay will be deducted from.',
      dk: 'Venligst udfyld konto oplysninger, hvor din Økonomiforsikring® skal opkræves fra.',
    }),

  Overview: () => ({
    en: 'Overview',
    dk: 'Overblik',
  }),

  'Order number': () => ({
    en: 'Order number',
    dk: 'Ordrenummer',
  }),

  'Continue to loan offers': () => ({
    en: 'Continue to loan offers',
    dk: 'Forsæt til lånetilbud',
  }),

  'Insurance provided in collaboration with': () => ({
    en: 'Insurance provided in collaboration with',
    dk: 'I samarbejde med',
  }),

  'Yes, continue with insurance': () => ({
    en: 'Yes, continue with insurance',
    dk: 'Tilkøb forsikring ',
  }),

  'Refresh page': () => ({
    en: 'Refresh page',
  }),

  'No, move forward without insurance': () => ({
    en: 'No, move forward without insurance',
    dk: 'Fortsæt uden forsikring',
  }),

  'Try later': () => ({
    en: 'Try later',
    dk: 'Prøv senere',
  }),

  'Payment failed - headline': () => ({
    en: 'Payment failed - headline',
  }),

  'Session expired - headline': () => ({
    en: 'Session expired - headline',
  }),

  'Something went wrong - headline': () => ({
    en: 'Something went wrong - headline',
  }),

  'Payment failed - body': () => ({
    en: 'Payment failed - body',
  }),

  'Session expired - body': () => ({
    en: 'Session expired - body',
  }),

  'Something went wrong - body': () => ({
    en: 'Something went wrong - body',
  }),

  'To proceed, you must be eligible': () => ({
    en: 'To proceed, you must be eligible',
  }),

  'To proceed, you must accept the terms': () => ({
    en: 'To proceed, you must accept the terms',
    dk: 'Venligst godkend betingelserne ovenfor',
  }),
};
