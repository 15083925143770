import { useQuery } from 'react-query';
import {
  insuranceRequestKeys,
  parseInsuranceRequestKey,
} from '../cache-keys/insuranceRequestKeys';
import { insurance_request } from '@axo/shared/data-access/types';
import { insurance_request_api } from '@axo/shared/data-access/api';
import { useAPI } from '@axo/shared/data-access/provider';
import { queryFunction } from '../query-function';

export function useInsuranceRequest(id?: string) {
  const api = useAPI();
  const cacheKey = insuranceRequestKeys.root({
    insuranceRequestID: id ?? '',
  });
  return useQuery(
    cacheKey,
    queryFunction(
      insurance_request_api.getInsuranceRequest(api),
      ({ queryKey }) => parseInsuranceRequestKey(queryKey).insuranceRequestID
    ),
    {
      enabled: !!id,
      // refetch every second until conditions are met
      refetchInterval: (data) =>
        data?.Status === insurance_request.Status.New ||
        (data?.Status === insurance_request.Status.Approved && !data.PolicyID)
          ? 1_000
          : false,
      keepPreviousData: true,
    }
  );
}
