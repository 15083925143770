import formStyles from '../../InsuranceForm/InsuranceForm.module.scss';
import { useTranslation } from '../../translations/useTranslation';

export function EligibilityCriteria() {
  const { t } = useTranslation();

  return (
    <>
      <h4>{t('Eligibility criteria')}</h4>
      <div className={formStyles.scrollableInfoBox}>
        <p>
          {t(
            'It is a condition for the insurance to come into force that you:'
          )}
        </p>
        <ol>
          {[
            t('I am 18-65 years old'),
            t(
              'I live permanently in Denmark and I have a valid mitID as proof of belonging to Danish social security based on residence'
            ),
            t('I am permanently employed and work at least 16 hours a week'),
            t(
              'I am fully able to work and am not aware of future layoffs or layoffs'
            ),
            t(
              'I am not aware of any serious illness or chronic condition that could lead to sick leave. In addition, I am not aware of possible future sickness absences and I understand that the insurance only applies to accidents or illnesses that occur during the insurance period'
            ),
          ].map((content, index) => (
            <li key={`eligibility-item-${index}`}>{content}</li>
          ))}
        </ol>
      </div>
    </>
  );
}
