import { Hr, Icons, P } from '@axo/deprecated/util/ui-components';
import { NavWrapper, StepNavigator } from '@axo/insurance/ui';
import {
  EventCode,
  useEventField,
  useEventLogger,
} from '@axo/shared/data-access/event-log';
import { useAnalytics } from '@axo/shared/services/analytics';
import { classNames } from '@axo/shared/util/dom';
import formStyles from '../../InsuranceForm/InsuranceForm.module.scss';
import { IPayOutAndPremium, currencyFormatter } from '../../const';
import { useTranslation } from '../../translations/useTranslation';
import styles from './Summary.module.scss';

export const Summary = ({
  onEnd,
  selectedInsurance,
}: {
  onEnd?: () => void;
  selectedInsurance: IPayOutAndPremium;
}) => {
  const { t } = useTranslation();
  const log = useEventLogger();
  const { track } = useAnalytics();
  useEventField('current-step', 'summary');

  async function handleNext() {
    log(EventCode.InsuranceNext);
    await track({
      event: 'Insurance Step summary Completed',
      options: {
        send_immediately: true,
      },
    });

    if (onEnd) {
      onEnd();
    }
  }

  return (
    <div className={classNames(formStyles.stepContent, styles.summary)}>
      <h3 className={styles.thankYou}>
        <Icons.Shield size="md" color={{ primary: '--primary-500' }} />
        {t('Thank you for your purchase')}!
      </h3>

      <p>
        {t(
          'We have registered your purchase of LendMe Financial Protection. You will receive an email with more details about your new insurance within 24 hours.'
        )}
      </p>
      <p>
        {t(
          'An email with details about the insurance will be sent to you within 24 hours. If you have any further questions, do not hesitate to contact us.'
        )}
      </p>

      <Hr className={formStyles.separator} />

      <div className={styles.infoBox}>
        <div className={styles.infoHeader}>
          <h4 className={styles.thankYou}>
            <Icons.Shield size="sm" color={{ primary: '--primary-500' }} />
            {t('Overview')}
          </h4>
        </div>

        <div className={styles.infoItem}>
          <P className={styles.label}>{t('Value insured')}</P>
          <span className={styles.value}>
            +{currencyFormatter(selectedInsurance.payout)}/{t('month')}
          </span>
        </div>
        <div className={styles.infoItem}>
          <P className={styles.label}>{t('Monthly payment')}</P>
          <span className={styles.value}>
            -{currencyFormatter(selectedInsurance.premium)}/{t('month')}
          </span>
        </div>
      </div>

      <NavWrapper className={formStyles.stepNavigation}>
        <StepNavigator
          rightButton={{
            text: t('Continue to loan offers'),
            variant: 'enabled',
            width: 'full-width',
            onClick: handleNext,
          }}
        />
      </NavWrapper>
    </div>
  );
};
